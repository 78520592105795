@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

:root {
  --darkbackground: #00a1bd;
  --lightBackground: #e6f6f9;
  --darkText: #002944;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
  max-width: 1204px;
  margin: auto;
  font-family: "Roboto", sans-serif;
}

.information-form {
  display: grid;
  grid-template-columns: repeat(3, minmax(400px));
  grid-template-rows: repeat(4, auto);
  padding: 30px 50px;
  margin-bottom: 20px;
}

.information-form p {
  margin-top: 40px;
  margin-bottom: 10px;
}

.information-form input {
  font-size: 20px;
  border: none;
  outline: none;
  border-bottom: 2px solid rgba(0, 41, 68, 0.2);
  padding-bottom: 10px;
}

.full-width {
  grid-column: 1 / 4;
  width: 1101px;
}

.width-385 {
  width: 385px;
}
.width-263 {
  width: 263px;
}
.width-257 {
  width: 257px;
}
.address-width {
  width: 1000px;
}

.df {
  display: block;
}
.width100 {
  width: 100%;
}
.fnwidth40 {
  width: 100%;
}
.lnwidth35 {
  width: 100%;
}
.gnwidth25 {
  width: 100%;
}

.button {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #ffffff;
  background: #00a1bd;
  border-radius: 6px;
  width: 355.16px;
  height: 60px;
  border: none;
  cursor: pointer;
}

/* ipad */

@media (min-width: 750px) {
  .information-form {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 40px;
  }
  .full-width {
    width: 400px;
  }
  .info {
    margin: 0px 12px;
  }
  .df {
    display: flex;
  }

  .fnwidth40 {
    width: 40%;
  }
  .lnwidth35 {
    width: 35%;
  }
  .gnwidth25 {
    width: 25%;
  }
}

/* iphone 8 + */

@media (max-width: 415px) {
  .information-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 20px;
  }
  .max-width {
    width: 350px;
  }
  .button {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #ffffff;
    background: #00a1bd;
    border-radius: 6px;
    width: auto;
    height: auto;
    border: none;
    cursor: pointer;
  }
}

/* iphone 8 */

@media screen and (max-width: 375px) {
  .information-form {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    justify-content: center;
  }
  .max-width {
    width: 315px;
  }
}

/* iphone SE/5 */

@media screen and (max-width: 320px) {
  .information-form {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 20px;
    justify-content: center;
  }
  .max-width {
    width: 250px;
  }
}

input[type="text"],
select,
textarea {
  width: 100%;
  border-bottom: 1px solid #ccc;
  resize: vertical;
}
input[type="email"],
select,
textarea {
  width: 100%;
  border-bottom: 1px solid #ccc;
  resize: vertical;
}
