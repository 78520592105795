@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

:root {
  --darkbackground: #00a1bd;
  --lightBackground: #e6f6f9;
  --darkText: #002944;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
  font-family: "Roboto", sans-serif;
}

.main-div {
  width: auto;
  height: auto;
}

.calendar {
  width: auto;
  /* height: 500px; */
  padding: 30px 50px;
  background: #ffffff;
  border: 1px solid #002944;
  border-radius: 4px;
}

.title {
  margin-bottom: 30px;
}

.react-calendar {
  width: 70% !important;
  height: auto !important;
  max-width: 100%;
  background: rgba(230, 246, 249, 0.4);
  border-radius: 2px;
  color: rgba(96, 122, 138, 0.5);
}

.react-calendar__tile {
  max-width: 100%;
  padding: 25px 6.6667px !important;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__navigation button {
  color: var(--darkText);
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  /* width: 10px;
  height: 20px; */
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em !important;
}

.rc_root,
.rc_shortcut_cal_root {
  width: 100%;
}

.rc_root * {
  width: 100% !important;
  height: auto !important;
}

.rc_body-weekdays_cell {
  color: var(--rc-color-text-weekday-label);
  font-size: 0.83em;
  text-transform: uppercase;
  text-align: center !important;
}

.rc_root button {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 20px !important;
  width: auto !important;
}

.rc_body-weekdays_cell {
  color: var(--rc-color-text-weekday-label);
  font-size: 0.83em;
  text-transform: uppercase;
  padding: 15px !important;
}

@media screen and (max-width: 520px) {
  .react-calendar {
    width: 100% !important;
    height: auto !important;
    max-width: 100%;
    background: rgba(230, 246, 249, 0.4);
    border-radius: 2px;
    color: rgba(96, 122, 138, 0.5);
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px !important;
    background: none;
    text-align: center;
    line-height: 16px;
  }
  .rc_body-days-of-month .rc_body-cell.rc_highlight:not(.rc_disabled) button,
  .rc_body-days-of-month .rc_body-cell.rc_today:not(.rc_disabled) button {
    border-bottom: 0px solid !important;
  }
}

@media screen and (max-width: 375px) {
  .calendar {
    width: auto;
    /* height: 500px; */
    padding: 30px 20px;
    background: #ffffff;
    border: 1px solid #002944;
    border-radius: 4px;
  }
}
/* .react-calendar__tile--now {
  background: var(--darkbackground);
  border-radius: 4px;
  font-weight: bold;
  color: #ffffff;
}


.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
 background: #f8f8fa;
 color: #6f48eb;
 border-radius: 6px;
} */
