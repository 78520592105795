@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

:root {
  --darkbackground: #00a1bd;
  --lightBackground: #e6f6f9;
  --darkText: #002944;
  --grayText: #6c6c6c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
  margin: auto;
  font-family: "Roboto", sans-serif;
}

.payment-content {
  padding: 30px 50px;
}

.payment-content h4 {
  font-size: 24px;
  color: var(--darkText);
}

.payment-type {
  display: flex;
  font-size: 16px;
  color: var(--grayText);
  margin: 12px 12px 12px 0px;
  accent-color: var(--darkText);
}

.payment-type p {
  margin-left: 12px;
}

input [type="radio"] {
  width: 200px;
  height: 20px;
  vertical-align: middle;
  accent-color: var(--darkText);
}

@media (max-width: 415px) {
  .payment-content {
    padding: 30px 20px;
  }
  .payment-content h4 {
    font-size: 20px;
    color: var(--darkText);
  }
}
@media screen and (max-width: 375px) {
  .payment-content {
    padding: 30px 20px;
  }
}
