@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

:root {
  --darkbackground: #00a1bd;
  --lightBackground: #e6f6f9;
  --darkText: #002944;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
}

.accordion {
  width: auto;
  margin: auto;
  margin-bottom: 10px;
}

.accordion-title {
  width: auto;
  padding: 26px 0px;
  /* height: 87px; */
  background-color: var(--lightBackground);
  font-family: "Inter", sans-serif;
  display: flex;
  cursor: pointer;
  color: var(--darkText);
  font-size: 28px;
}

.accordion-title h4 {
  margin: auto;
}

.accordion-icon {
  /* margin-top: 25px; */
  padding-right: 25px;
}

.accordion img {
  width: 30px;
  /* margin: auto; */
}

.accordion-title-p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.accordion-title-p-sel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  /* line-height: 34px; */
}

/* iphone 8/7/6 plus */

@media (max-width: 414px) {
  .accordion-icon img {
    width: 20px;
  }
  .accordion-title {
    font-size: 20px;
    padding: 20px 0px;
  }
  .downArrow {
    /* padding-top: 8px; */
    margin-right: 12px;
  }
  .accordion-title-p-sel {
    font-size: 20px;
  }
  .accordion-title-p {
    font-size: 18px;
  }
}

/* iphone 12 pro and pixel 5 */

@media screen and (min-width: 390px) and (max-width: 393px) {
  .accordion-icon {
    padding-right: 29px;
  }
  .accordion-icon img {
    width: 20px;
  }
  .downArrow {
    /* padding-top: 8px; */
  }
}

/* Samsung galaxy S8+ */

/* @media (width: 360px) {
  .accordion-icon{
    margin-top: 25px;
    padding-right: 0px;
  }
  .accordion-icon img{
    margin-top: 25px;
    padding-right: 15px;
  }
} */

/* iphone 8 */

@media (max-width: 375px) and (min-width: 360px) {
  .accordion-icon img {
    width: 20px;
  }
  .accordion-title {
    font-size: 20px;
    padding: 10px 0px;
  }
  .accordion-icon {
    margin-top: 25px;
    padding-right: 8px;
  }
  .downArrow {
    margin-right: 5px;
  }
}

/* iphone SE/5 */

@media (max-width: 320px) {
  .accordion-icon img {
    width: 20px;
  }
  .accordion-title {
    font-size: 20px;
  }
  .accordion img {
    margin: 0px 0px 0px 0px;
  }
  .downArrow {
    /* padding-top: 8px; */
  }
  .upArrow {
    padding-top: 5px;
    margin-right: -15px;
  }
}
