@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

:root {
  --grayText: #6c6c6c;
  --darkText: #002944;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}

.main-container {
  width: auto;
  margin: auto;
  padding: 0px 50px;
  background-color: #ffffff;
}

.heading {
  color: var(--darkText);
  font-size: 24px;
  padding: 25px 0px;
  font-family: "Roboto", sans-serif;
}

.info {
  font-size: 16px;
  margin-top: 20px !important;
  color: var(--grayText);
  accent-color: var(--darkText);
}

label {
  margin: 12px;
}

.hotel h5 {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 12px;
  color: var(--darkText);
}

.address {
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.place-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 250px;
  height: 80px;
  font-size: 14px;
  color: #969696;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid var(--darkText);
  margin: 10px 10px 10px 0;
}

.place-info h4 {
  font-size: 18px;
  color: var(--darkText);
}

.transfer_time {
  display: flex;
  flex-direction: column;
}

.transfer_time h5 {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 12px;
  color: var(--darkText);
}

input[type="date"],
[type="time"] {
  border: none;
  outline: none;
  padding-right: 12px;
}

input[type="date"],
[type="time"] ::placeholder {
  width: 80px;
  height: 37px;
}

.date_time {
  display: flex;
}
.date_time img {
  width: 32px;
  height: 32px;
}

.date {
  margin-right: 20px;
  font-size: 14px;
  margin-bottom: 15px;
}

.time {
  font-size: 14px;
  margin-bottom: 15px;
}

/* DatePicker css */

.react-datepicker-wrapper {
  width: 135px;
  height: 77px;
}

.react-datepicker-wrapper input[type="text"] {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--darkText);
  background: url("../config/assets/images/calendar.png") no-repeat right;
  background-size: 20px;
  width: 135px;
  height: 40px;
  cursor: pointer;
}

.react-datepicker--time-only {
  background: url("../config/assets/images/clock.png") no-repeat right;
}

.react-datepicker-wrapper input[type="time"] {
  background: url("../config/assets/images/clock.png") no-repeat right;
}

.accomBox {
  justify-content: space-between;
  display: flex;
}
/* .modalView {
  width: auto;
  height: auto;
} */

/* Media Query */

/* iphone SE/5 */

@media screen and (max-width: 320px) {
  .main-container {
    padding: 0 20px;
  }
  .react-datepicker-wrapper input[type="text"] {
    width: 120px;
  }
  .heading h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 375px) {
  .heading h4 {
    font-size: 20px;
  }
  /* .modalView {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  } */
}

.rc_body-days-of-month
  .rc_body-cell.rc_disabled
  .rc_body-cell_value
  button::before {
  transform: rotate(90deg) !important;
}

.rc_body-days-of-month .rc_body-cell.rc_disabled .rc_body-cell_value button::before {
  transform: rotate(90deg)!important;
}
