@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

:root {
  --darkbackground: #00a1bd;
  --lightBackground: #e6f6f9;
  --darkText: #002944;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
}

.main {
  width: auto;
  height: auto;
  font-family: "Roboto", sans-serif;
  /* max-width: 1204px; */
}

.services {
  height: auto;
  border-bottom: 1px solid #00a1bd;
  background-color: var(--lightBackground);
}

.service-heading {
  font-size: 24px;
  padding: 30px 50px;
}

.services-types {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  /* justify-content: center; */
  margin-bottom: 15px;
  padding: 0px 40px;
}

.card {
  max-height: 33px;
  position: relative;
  /* top: 6px; */
}

.services-types img {
  display: flex;
  width: 44px;
  height: 44px;
  /* margin-top: 9px;
  margin-left: 10px; */
}

.service {
  display: flex;
  align-items: center;
  width: 264px;
  height: 89px;
  background: #ffffff;
  border: 1px solid rgba(69, 54, 2, 0.13);
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 0px 8px 15px 8px;

  /* margin: 0px 0px 20px 40px; */
}

.background {
  background: rgba(226, 175, 44, 0.4);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 62px;
  width: 62px;
  margin-left: 20px;
}

.visit_type {
  margin-left: 15px;
}

.language {
  width: auto;
  height: auto;
  padding-top: 30px;
  padding-right: 50px;
}

.language-content {
  width: 264px;
  max-height: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(69, 54, 2, 0.13);
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 21px;
  justify-content: space-evenly;
  float: right;
}

select {
  border: none;
  font-size: 21px;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 1px solid #ccc;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #00a1bd;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.btn_loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #00a1bd;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* iphone 8 */

@media (width: 375px) {
  .services-types {
    justify-content: space-between;
  }
}

/* iphone 8/7/6 plus */

@media screen and (max-width: 415px) {
  .service {
    margin: 0px 0px 20px 0px;
  }
  .services-types {
    padding-left: 0px;
    justify-content: center;
  }
  .service-heading {
    padding: 30px 70px;
  }
  .services-types {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;
    /* justify-content: center; */
    margin-bottom: 15px;
    padding: 0px 0px;
  }
  .language {
    width: auto;
    height: auto;
    padding-top: 30px;
    padding-right: 0px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .card {
    max-height: 20px;
    max-width: 20px;
    position: relative;
    /* top: 2px !important; */
  }
  .service-heading h4 {
    font-size: 20px;
    color: var(--darkText);
  }
}

/* iphone 12 pro and pixel 5 */

@media screen and (min-width: 390px) and (max-width: 393px) {
  .service {
    margin: 0px 0px 20px 0px;
  }
  .services-types {
    padding-left: 0px;
    justify-content: center;
  }
  .service-heading {
    padding: 30px 65px;
  }
}

/* iphone SE */

@media screen and (max-width: 320px) {
  .service {
    margin: 0px 0px 20px 0px;
  }
  .services-types {
    padding-left: 0px;
    justify-content: center;
  }
  .service-heading {
    padding: 30px;
  }
}

@media screen and (max-width: 375px) {
  .service-heading {
    font-size: 22px;
  }
}
